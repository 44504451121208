import type { Navigation, NavigationItem } from '@/services/documents';
import { removeEndSlash } from '@/util/endSlash';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useBreadcrumbs = (navigation: Navigation) => {
  const location = useLocation();

  const currentPagePathName = useMemo(
    () => removeEndSlash(location.pathname),
    [location.pathname],
  );

  const getAncestors = useCallback(
    (
      path: string,
      children: NavigationItem[] | undefined,
      ancestors: NavigationItem[] = [],
    ): NavigationItem[] | undefined => {
      if (!children) return undefined;
      for (const item of children) {
        if (item.link === path) {
          return ancestors.concat(item);
        }
        const found = getAncestors(
          path,
          item.navigation?.items,
          ancestors.concat(item),
        );
        if (found) {
          return found;
        }
      }
      return undefined;
    },
    [],
  );

  const ancestors = useMemo(() => {
    return getAncestors(currentPagePathName, navigation.items);
  }, [getAncestors, currentPagePathName, navigation.items]);

  return {
    ancestors,
  };
};
