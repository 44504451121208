import throttle from 'lodash.throttle';
import { useCallback, useEffect, useMemo } from 'react';
import type { HeadingData } from './types';

export const useHeadingMenu = (htmlString: string | null) => {
  /**
   * h1-h3 までの HTML 文字列を抜き出す
   * @return string[]
   */
  const headings = useMemo(() => {
    if (!htmlString) return [];
    const matchHeadings = htmlString.match(/<h[1-3].+?<\/h[1-3]>/g);
    return matchHeadings ?? [];
  }, [htmlString]);

  /**
   * 各タグの文字列から必要な情報を返す
   */
  const getHeadingData = useCallback((item: string): HeadingData | null => {
    if (!item) return null;
    const sizeIndex = item.indexOf('<h');
    const size = item.charAt(sizeIndex + 2);
    const id = item.match(/id="([^"]*)">/);
    if (!size || !id) return null;
    const text = new DOMParser()
      .parseFromString(item, 'text/html')
      .body.innerText.replace('#', '');
    if (!text) return null;
    return {
      size: Number(size),
      id: id[1],
      text,
    };
  }, []);

  /**
   * スクロール時の処理
   */
  const handleScroll = useCallback(() => {
    const menuElements = [
      ...document.querySelectorAll('[data-heading-menu-id]'),
    ];
    const scrollY = window.scrollY;
    menuElements.forEach((element, i) => {
      const id = element.getAttribute('data-heading-menu-id');
      const headingElement = document.getElementById(`${id}`);
      if (!headingElement) return;
      const headingElementTop = headingElement.getBoundingClientRect().top;
      const sectionHeight = () => {
        if (i < menuElements.length - 1) {
          const nextHeadingId = menuElements[i + 1].getAttribute(
            'data-heading-menu-id',
          );
          if (!nextHeadingId) return 0;
          const nextHeadingElement = document.getElementById(nextHeadingId);
          if (!nextHeadingElement) return 0;
          const nextHeadingElementTop =
            nextHeadingElement.getBoundingClientRect().top;
          return nextHeadingElementTop - headingElementTop;
        } else {
          return document.body.scrollHeight - (headingElementTop + scrollY);
        }
      };
      const top = headingElementTop + scrollY - window.innerHeight * 0.1;
      if (scrollY > top && scrollY < top + sectionHeight()) {
        element.classList.add('current');
      } else {
        element.classList.remove('current');
      }
    });
  }, []);

  useEffect(() => {
    handleScroll();
    const throttled = throttle(handleScroll, 200);
    window.addEventListener('scroll', throttled);
    return () => window.removeEventListener('scroll', throttled);
  }, [handleScroll]);

  return {
    headings,
    getHeadingData,
  };
};
