import type { AuthorityTableData } from '@/services/config/authorityTable';
import { memo } from 'react';
import type { AuthorityTableVisibleType } from '../types';
import styled from '@emotion/styled';
import { mq } from '@/breakpoints';
import AuthorityTableBody from '../AuthorityTableBody';
import { css } from '@emotion/react';

type Props = {
  tableData: AuthorityTableData;
  visibleType: AuthorityTableVisibleType;
};

const AuthorityTable: React.FC<Props> = ({ tableData, visibleType }) => {
  return (
    <Table
      data-column-count={tableData.roles.length + 2}
      data-visible={visibleType}
    >
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>
            <span>SCOPE</span>
          </th>
          {tableData.roles.map((role) => (
            <th key={role}>
              <span>{role}</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <AuthorityTableBody tableData={tableData} visibleType={visibleType} />
      </tbody>
    </Table>
  );
};

export default memo(AuthorityTable);

const tdWidth = (roleCount: number) => {
  const columnCount = roleCount + 2;
  const style = `
    &[data-column-count='${columnCount}'] {
      &[data-visible='summary'] {
        th,
        td {
          width: ${100 / (columnCount - 1)}%;
        }
      }
      &[data-visible='detail'] {
        th,
        td {
          width: ${100 / columnCount}%;
        }
      }
    }
  `;
  return css`
    ${style}
  `;
};

const Table = styled.table`
  width: 100% !important;
  display: table !important;
  font-family: futura-pt;
  font-weight: 450;
  border-collapse: collapse;

  thead,
  tbody {
    width: 100%;
  }

  tbody {
    tr {
      &:hover {
        background-color: #c9d4f5;
      }
    }
  }

  ${mq.md} {
    width: 900px !important;
    max-width: unset !important;
  }

  th,
  td {
    padding: 10px 20px;
    text-align: center;
    box-sizing: border-box;
    border-bottom: none !important;
    word-break: break-word;

    &:first-of-type {
      text-align: right;
    }
  }

  &[data-visible='summary'] {
    th,
    td {
      &:nth-of-type(2) {
        display: none;
      }
    }
  }

  ${tdWidth(1)};
  ${tdWidth(2)};
  ${tdWidth(3)};
  ${tdWidth(4)};
  ${tdWidth(5)};
  ${tdWidth(6)};
  ${tdWidth(7)};
  ${tdWidth(8)};

  thead {
    margin-bottom: 4px;
  }

  th {
    position: relative;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.2em;
    position: sticky;
    z-index: 1;
    top: ${68 + 79}px;
    background-color: white;

    ${mq.md} {
      top: 0;
    }

    span {
      position: relative;
    }

    &::before {
      position: absolute;
      bottom: 0;
      left: 2px;
      width: calc(100% - 4px);
      height: 100%;
      content: '';
      display: block;
      z-index: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:nth-of-type(2) {
      color: black !important;

      &::before {
        background-color: #ececec;
      }
    }
    &:nth-of-type(3n + 3) {
      color: white !important;

      &::before {
        background-color: #101224;
      }
    }
    &:nth-of-type(3n + 4) {
      color: black !important;

      &::before {
        background-color: #6eb6cc;
      }
    }
    &:nth-of-type(3n + 5) {
      color: black !important;

      &::before {
        background-color: #d9d9d9;
      }
    }
  }

  td {
    border-left: 1px solid black;
    font-size: 12px;

    &:first-of-type {
      border-left: none;

      a {
        display: inline-block;
        text-decoration: none;
        border: solid 1px #3098a6;
        border-radius: 50%;
        line-height: 14px;
        width: 16px;
        height: 16px;
        text-align: center;
        margin-left: 4px;
      }
    }

    &:nth-of-type(n + 2) {
      span {
        visibility: hidden;
        display: block;
        line-height: 0;

        &::after {
          content: '';
          margin: 0 auto;
          visibility: visible;
          display: block;
          box-sizing: border-box;
          width: 12px;
          height: 8px;
          border-left: solid 2px black;
          border-bottom: solid 2px black;
          transform: rotate(-45deg);
        }
      }
    }

    &:last-of-type {
      position: relative;
      &::after {
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: black;
        content: '';
        display: block;
      }
    }

    &.action-name {
      font-size: 16px;
    }

    &.scope-list {
      text-align: left;
      word-break: break-all;
    }

    &.parameter-name {
      font-size: 14px;
      text-transform: uppercase;
    }

    &.parameter-none {
      position: relative;

      &::before {
        display: block;
        content: '';
        width: 10px;
        height: 1px;
        background-color: black;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }

    &.parameter-value {
      text-transform: uppercase;
    }
  }
`;
