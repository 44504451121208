import { useCallback, useState } from 'react';
import { useMount } from 'react-use';
import type { AuthorityTableVisibleType } from './types';
import {
  useAuthorityTableData,
  type AuthorityTableData,
} from '@/services/config/authorityTable';

export const useServiceAuthorityTable = (serviceName: string) => {
  const [visibleType, setVisibleType] =
    useState<AuthorityTableVisibleType>('summary');
  const [authorityTableData, setAuthorityTableData] =
    useState<AuthorityTableData>();
  const { getAuthorityTableData } = useAuthorityTableData();

  const handleClickSummary = useCallback(() => {
    setVisibleType('summary');
  }, []);

  const handleClickDetail = useCallback(() => {
    setVisibleType('detail');
  }, []);

  useMount(async () => {
    const res = await getAuthorityTableData(serviceName);
    setAuthorityTableData(res);
  });

  return {
    authorityTableData,
    visibleType,
    handleClickDetail,
    handleClickSummary,
  };
};
