import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SideMenu from '@/components/common/SideMenu';
import LowerWrapper from '@/components/common/LowerWrapper';
import DocumentWrapper from '@/components/common/DocumentWrapper';
import { markdownToString, useProcessor } from '@/util/markdown';
import Helmet from '@/components/common/Helmet';
import { Navigation } from '@/services/documents/navigation/types';
import { useLocation, useParams } from 'react-router-dom';
import type { EntryData } from '@/services/documents/entry/types';
import { useTranslation, Trans } from 'react-i18next';
import NotFound from '@/pages/not_found';
import Admonition from '@/components/common/markdown/Admonition';
import styled from '@emotion/styled';
import { mq } from '@/breakpoints';
import {
  ReleaseNotesData,
  useEntryAPI,
  useNavigation,
  useReleaseNotes,
} from '@/services/documents';
import { usePrevious } from 'react-use';
import { DateTime } from 'luxon';
import ReleaseNoteList from '@/components/common/ReleaseNoteList';
import UnAuthentication from '@/components/common/UnAuthentication';
import { Oval } from 'react-loader-spinner';
import Breadcrumbs from '@/components/common/Breadcrumbs';
import { documentAPIOrigin } from '@/util/appConfig';
import OrderNavigation from '@/components/common/OrderNavigation';
import HeadingMenu from '@/components/common/HeadingMenu';

const EntryPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { getNavigation } = useNavigation();
  const { getEntry } = useEntryAPI();
  const { getReleaseNotes } = useReleaseNotes();
  const [navigationData, setNavigationData] = useState<Navigation>();
  const processor = useProcessor();
  const params = useParams<{ page_id: string }>();
  const prevParams = usePrevious(params);
  const location = useLocation();
  const prevLanguage = usePrevious(i18n.language);
  const [htmlString, setHtmlString] = useState<string | null>(null);
  const [contents, setContents] = useState<React.ReactNode | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // ローディング
  const loading = useMemo(() => {
    if (!isLoading) return null;
    return (
      <LoadingWrapper>
        <Oval
          color="#3098a6"
          secondaryColor="#639ea6"
          width={40}
          height={40}
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
      </LoadingWrapper>
    );
  }, [isLoading]);

  // リリースノートページかどうか
  const isReleaseNotePage = useMemo(
    () => location.pathname.includes('/release-notes'),
    [location.pathname],
  );

  // リリースノートの一覧ページかどうか
  const isReleaseNoteListPage = useMemo(
    () => isReleaseNotePage && /release-notes\/?$/.test(location.pathname),
    [isReleaseNotePage, location.pathname],
  );

  const setupContents = useCallback(
    async (entryData: EntryData) => {
      const currentLanguage = window.location.pathname.includes('/en/')
        ? 'en'
        : 'ja';
      const otherLanguage = currentLanguage === 'ja' ? 'en' : 'ja';
      // 現在選択されている言語のコンテンツがある場合そのまま表示
      // 無い場合もう一方の言語のコンテンツを表示
      const currentLanguageEntry = entryData[currentLanguage];
      const entry = currentLanguageEntry ?? entryData[otherLanguage];
      const onlyOtherLanguage = !currentLanguageEntry;
      // どちらも無い場合 Not Found を表示
      if (!entry) {
        setContents(<NotFound />);
        setIsLoading(false);
        return;
      }
      const contentToString = () => {
        try {
          return markdownToString(
            entry.content,
            entry.slug,
            t('entry.footnote_label'),
          );
        } catch (error) {
          console.log(error);
          return null;
        }
      };
      const convertedString = contentToString();

      setHtmlString(convertedString);

      if (convertedString) {
        // どちらかの言語のコンテンツが有る場合
        const body = await processor.process(convertedString);
        setContents(
          <>
            <Helmet
              title={entry.title}
              description={entry.description}
              image={`${documentAPIOrigin}${entry.thumbnail}`}
            />
            <article className="markdown-body">
              {onlyOtherLanguage && (
                <OtherLanguageAttentionWrapper>
                  <Admonition
                    type="caution"
                    title={<Trans i18nKey="entry.other_language_only" />}
                  />
                </OtherLanguageAttentionWrapper>
              )}
              {entry.title && <h1>{entry.title}</h1>}
              {isReleaseNotePage && (
                <Date>
                  {DateTime.fromISO(entry.published_at).toFormat(
                    t('date.format'),
                  )}
                </Date>
              )}
              {body.result}
            </article>
          </>,
        );
        setIsLoading(false);
        return;
      }
      setContents(<NotFound />);
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [processor, i18n.language, isReleaseNotePage],
  );

  const setupReleaseNoteList = useCallback(
    (releaseNotesData: ReleaseNotesData) => {
      setContents(<ReleaseNoteList data={releaseNotesData} />);
      setIsLoading(false);
    },
    [],
  );

  const setup401 = useCallback(() => {
    setContents(<UnAuthentication />);
    setIsLoading(false);
  }, []);

  const setup403 = useCallback(() => {
    setContents(<>403</>);
    setIsLoading(false);
  }, []);

  // navigation.yml 取得
  useEffect(() => {
    (async () => {
      if (!params.page_id) return;
      if (
        prevLanguage !== i18n.language ||
        params.page_id !== prevParams?.page_id
      ) {
        const navigationRes = await getNavigation(
          i18n.language,
          params.page_id,
        );
        setNavigationData(navigationRes);
      }
    })();
  }, [
    getNavigation,
    params.page_id,
    prevParams?.page_id,
    i18n.language,
    prevLanguage,
  ]);

  // ページコンテンツ取得 （リリースノートの一覧以外）
  useEffect(() => {
    (async () => {
      if (isReleaseNoteListPage) return;
      if (!params.page_id) return;
      setIsLoading(true);
      setContents(null);
      setHtmlString(null);
      const path = location.pathname.substring(
        location.pathname.indexOf(params.page_id),
      );
      const entryRes = await getEntry(path);
      if (entryRes.is401) {
        setup401();
      } else if (entryRes.is403) {
        setup403();
      } else {
        setupContents(entryRes);
      }
    })();
  }, [
    getEntry,
    location.pathname,
    params.page_id,
    setupContents,
    setup401,
    setup403,
    isReleaseNoteListPage,
  ]);

  // サイドメニューのリリースノート一覧
  useEffect(() => {
    (async () => {
      if (!isReleaseNoteListPage) return;
      if (!params.page_id) return;
      setContents(null);
      const path = location.pathname.substring(
        location.pathname.indexOf(params.page_id),
      );
      setIsLoading(true);
      const releaseNotesRes = await getReleaseNotes(path);
      setupReleaseNoteList(releaseNotesRes);
    })();
  }, [
    isReleaseNoteListPage,
    location.pathname,
    params.page_id,
    getReleaseNotes,
    setupReleaseNoteList,
  ]);

  //
  useEffect(() => {
    if (isReleaseNoteListPage || !contents) return;
    (async () => {
      const anchorElements = document.querySelectorAll('a[href^="#"]');
      Array.prototype.slice
        .call(anchorElements)
        .forEach((a: HTMLAnchorElement) => {
          a.addEventListener('click', (e) => {
            e.preventDefault();
            let href = a.getAttribute('href');
            if (href) {
              href = decodeURIComponent(href);
              const targetElement = document.getElementById(
                href.replace('#', ''),
              );
              if (targetElement) {
                const headerElement = document.querySelector(
                  'header[data-id="header"]',
                );
                const headerHeight = headerElement
                  ? headerElement.clientHeight
                  : 0;
                const scrollTop =
                  targetElement.getBoundingClientRect().top +
                  window.scrollY -
                  headerHeight;
                window.history.pushState(null, '', href);
                window.scrollTo({ top: scrollTop, behavior: 'smooth' });
              }
            }
            return false;
          });
        });

      // ページ表示時スクロール
      const markdownBodyElement = document.querySelector('.markdown-body');
      if (!markdownBodyElement) return;
      const imgSrcList = [];
      const rex = /<*img[^>]*src *= *["']?([^"']*)/g;
      let match;
      while ((match = rex.exec(markdownBodyElement.innerHTML))) {
        imgSrcList.push(match[1]);
      }
      // 画像を読み込む
      const loadImage = async (src: string) => {
        const promise = new Promise<void>((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            resolve();
          };
          img.onerror = () => {
            reject();
          };
          img.src = src;
        });
        await promise;
      };
      for (const imgSrc of imgSrcList) {
        const isExternalPath = imgSrc.match(/^https?/);
        if (isExternalPath) {
          try {
            await loadImage(imgSrc);
          } catch (err) {
            console.log(err);
          }
        }
      }
      // 読み込み完了後にスクロール
      const decodedHash = decodeURIComponent(
        window.location.hash.replace('#', ''),
      );
      const targetElement = document.getElementById(decodedHash);
      if (!targetElement) return;
      const headerElement = document.querySelector('header[data-id="header"]');
      const headerHeight = headerElement ? headerElement.clientHeight : 0;
      const scrollTop =
        targetElement.getBoundingClientRect().top +
        window.scrollY -
        headerHeight;
      window.scrollTo({ top: scrollTop, behavior: 'smooth' });
    })();
  }, [isReleaseNoteListPage, contents]);

  return (
    <LowerWrapper>
      <SideMenu data={navigationData} />
      <DocumentWrapper>
        <div className="contents">
          {navigationData && <Breadcrumbs navigation={navigationData} />}
          {isLoading ? loading : contents}
          {navigationData && !loading && (
            <OrderNavigation navigation={navigationData} />
          )}
        </div>
        {!isReleaseNoteListPage && <HeadingMenu htmlString={htmlString} />}
      </DocumentWrapper>
    </LowerWrapper>
  );
};

export default EntryPage;

const OtherLanguageAttentionWrapper = styled.div`
  margin-bottom: 60px;

  ${mq.md} {
    margin-bottom: 40px;
  }
`;

const Date = styled.p`
  margin-bottom: 3em;
  font-family: 'Inter';
  color: #aaa;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
