import styled from '@emotion/styled';
import type { NestWrapperProps } from '../SideMenu/types';
import { css } from '@emotion/react';
import { mq } from '@/breakpoints';
import { memo } from 'react';

const SideMenuTitle = styled.p<
  NestWrapperProps & { isReleaseNoteListPage?: boolean }
>`
  line-height: 1.5;
  margin-top: 28px;
  margin-bottom: 0;
  padding-bottom: 4px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.02em;

  ${({ isReleaseNoteListPage }) => {
    if (isReleaseNoteListPage) {
      return css`
        &:nth-of-type(n + 2) {
          margin-top: 0;
        }
      `;
    }
  }}

  a,
  span {
    padding: 8px 16px 8px 32px;
    display: block;
    cursor: pointer;
  }

  a {
    span {
      padding: 0;
    }
  }

  &.nest {
    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &::after {
        content: '';
        display: block;
        width: 6px;
        height: 8px;
        background-repeat: no-repeat;
        background-image: url(/assets/common/sidemenu/icon_arrow_right_white.svg);
        transition: transform 200ms ease-out;
      }
    }
  }

  &.open {
    span {
      &::after {
        transform: rotate(90deg);
      }
    }
  }

  ${({ current }) => {
    if (current) {
      return css`
        a {
          font-weight: 700;
          border-radius: 0 4px 4px 0;
          background: rgba(31, 41, 51, 0.2);
        }
      `;
    }
  }}

  ${mq.md} {
    font-size: 12px;
  }
`;

export default memo(SideMenuTitle);
