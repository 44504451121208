import { mq } from '@/breakpoints';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';

export type AdmonitionType = 'note' | 'info' | 'tip' | 'caution' | 'danger';

type Props = {
  type: AdmonitionType;
  title?: string | React.ReactNode;
  children?: React.ReactNode;
};

const Admonition: React.FC<Props> = ({ type, title, children }) => {
  return (
    <Wrapper type={type}>
      <IconWrapper>
        <img src={`/assets/common/admonition/icon_${type}.svg`} />
      </IconWrapper>
      <ContentWrapper>
        <Title>{title ?? type.toUpperCase()}</Title>
        {children}
      </ContentWrapper>
    </Wrapper>
  );
};

export default memo(Admonition);

type WrapperProps = {
  type: AdmonitionType;
};

const Wrapper = styled.div<WrapperProps>`
  border-radius: 16px;
  padding: 24px;
  margin: 20px 0;
  display: flex;

  ${({ type }) => {
    if (type === 'note') {
      return css`
        background-color: #f3f3f3;
      `;
    }
    if (type === 'info') {
      return css`
        background-color: #f1f7ff;
      `;
    }
    if (type === 'tip') {
      return css`
        background-color: #e1fff0;
      `;
    }
    if (type === 'caution') {
      return css`
        background-color: #fff0e1;
      `;
    }
    if (type === 'danger') {
      return css`
        background-color: #ffece7;
      `;
    }
  }}

  ${mq.md} {
    border-radius: 8px;
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  margin-right: 12px;
`;

const ContentWrapper = styled.div`
  flex: 1;
  width: calc(100% - 12px);

  & > *:first-of-type {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.p`
  margin-top: 0;
  font-weight: bold;
`;
