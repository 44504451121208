import { Link } from 'react-router-dom';
import {
  GuideInfoProductDate,
  GuideInfoProductDescription,
  GuideInfoProductName,
  GuideItemIcon,
  GuideItemInfo,
  GuideSection,
  GuideTitle,
  GuideUpdateListItem,
  GuideUpdatesList,
  GuideUpdatesListWrapper,
  GuideUpdatesMenuTitle,
  GuideUpdatesMenuWrapper,
  GuideUpdatesWrapper,
  IndexContentsWrapper,
  ProductsOverviewProductItem,
  ProductsOverviewProductList,
  ProductsOverviewProductWrapper,
  ProductsOverviewTitle,
  ProductsOverviewWrapper,
} from '../styled';
import { useLangPath } from '@/i18n';
import { getBranchLink } from '@/util/branch';
import Button from '@/components/common/Button';
import { Product, ReleaseNote, useProducts } from '@/services/documents';
import { useEffect, useState } from 'react';
import { getContentsImagePath } from '@/util/contentsImagePath';
import { css } from '@emotion/react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

type Props = {
  releaseNotes: ReleaseNote[];
};

const DevelopersGuides: React.FC<Props> = ({ releaseNotes }: Props) => {
  const langPath = useLangPath();
  const { t } = useTranslation();
  const { getProducts } = useProducts();
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    (async () => {
      const res = await getProducts('developers-guides');
      setProducts(res);
    })();
  }, [getProducts]);

  return (
    <GuideSection id="developers-guide">
      <IndexContentsWrapper>
        <GuideTitle>
          DEVELOPERS’S
          <br />
          GUIDES
        </GuideTitle>
      </IndexContentsWrapper>
      <GuideUpdatesWrapper>
        <GuideUpdatesMenuWrapper>
          <GuideUpdatesMenuTitle>
            UPDATES OF
            <br />
            EACH PRODUCT
          </GuideUpdatesMenuTitle>
          <Button
            type="button"
            colorType="white"
            isLink
            href={`${langPath}release-notes/`}
          >
            RELEASE NOTES
          </Button>
        </GuideUpdatesMenuWrapper>
        <GuideUpdatesListWrapper>
          <GuideUpdatesList>
            {releaseNotes.map((note) => {
              const img = getContentsImagePath(note.thumbnail);
              return (
                <GuideUpdateListItem key={note.slug}>
                  <Link to={getBranchLink(note.slug.replace('/ja/', '/'))}>
                    <GuideItemInfo>
                      <GuideInfoProductName>{note.title}</GuideInfoProductName>
                      <GuideInfoProductDescription>
                        {note.description}
                      </GuideInfoProductDescription>
                      <GuideInfoProductDate>
                        {DateTime.fromISO(note.published_at).toFormat(
                          t('date.format'),
                        )}
                      </GuideInfoProductDate>
                    </GuideItemInfo>
                    <GuideItemIcon
                      css={css`
                        background-image: url(${img});
                      `}
                    />
                  </Link>
                </GuideUpdateListItem>
              );
            })}
          </GuideUpdatesList>
        </GuideUpdatesListWrapper>
      </GuideUpdatesWrapper>
      <ProductsOverviewWrapper>
        <ProductsOverviewTitle>PRODUCTS OVERVIEW</ProductsOverviewTitle>
        <ProductsOverviewProductWrapper>
          <ProductsOverviewProductList>
            {products.map((product, i) => (
              <ProductsOverviewProductItem
                key={`developers-guides-product${i}`}
              >
                <Link to={getBranchLink(`${langPath}${product.link}`)}>
                  <p>{product.name}</p>
                  <div className="link-icon" />
                </Link>
              </ProductsOverviewProductItem>
            ))}
          </ProductsOverviewProductList>
        </ProductsOverviewProductWrapper>
      </ProductsOverviewWrapper>
    </GuideSection>
  );
};

export default DevelopersGuides;
