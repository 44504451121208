import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useHeadingMenu } from './useHeadingMenu';
import HeadingMenuItem from '../HeadingMenuItem';
import { mq } from '@/breakpoints';

type Props = {
  htmlString: string | null;
};

const HeadingMenu: React.FC<Props> = ({ htmlString }: Props) => {
  const { headings, getHeadingData } = useHeadingMenu(htmlString);

  if (!htmlString || !headings.length) return null;

  return (
    <Wrapper>
      <div className="nav">
        <ul>
          {headings.map((item, i) => (
            <HeadingMenuItem
              key={`heading_menu_${i}`}
              data={getHeadingData(item)}
            />
          ))}
        </ul>
      </div>
    </Wrapper>
  );
};

export default HeadingMenu;

const Wrapper = styled.div`
  font-size: 14px;
  width: 284px;
  margin-top: 104px;
  margin-left: 60px;
  box-sizing: border-box;

  ${mq.md} {
    display: none;
  }

  .nav {
    position: sticky;
    top: 100px;
  }

  ul {
    margin: 0;
    padding: 36px 24px;
    list-style: none;
    z-index: 1;
    max-height: calc(100vh - 100px);
    overflow: auto;
    box-sizing: border-box;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient(white, #b2b2b2, white);
    }
  }

  li {
    margin-bottom: 6px;

    ${() => {
      return [...Array(3)].map((_, i) => {
        return css`
          &.size-${i + 1} {
            margin-left: ${(i - 1) * 24}px;
          }
        `;
      });
    }}

    &.current {
      a {
        color: #3098a6;
      }
    }
  }

  a {
    color: #575756;
    text-decoration: none;

    &:hover {
      color: #000;
    }
  }
`;
