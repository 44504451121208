import { Helmet as ReactHelmet } from 'react-helmet-async';
import { useHelmet } from './useHelmet';

type Props = {
  title?: string;
  description?: string;
  image?: string;
};

const Helmet: React.FC<Props> = ({ title, description, image }: Props) => {
  const { pageTitle, pageDescription } = useHelmet(title, description);

  return (
    <ReactHelmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      <meta name="description" content={pageDescription} />
      <meta property="og:description" content={pageDescription} />
      {image && <meta property="og:image" content={image} />}
    </ReactHelmet>
  );
};

export default Helmet;
