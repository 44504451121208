import { mq } from '@/breakpoints';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import AuthorityTable from './AuthorityTable';
import { useServiceAuthorityTable } from './useServiceAuthorityTable';

type Props = {
  serviceName: string;
};

const ServiceAuthorityTable: React.FC<Props> = ({ serviceName }) => {
  const {
    authorityTableData,
    visibleType,
    handleClickDetail,
    handleClickSummary,
  } = useServiceAuthorityTable(serviceName);

  if (!serviceName || !authorityTableData) return null;

  return (
    <Wrapper>
      <NavigationWrapper>
        <NavigationButton
          selected={visibleType === 'summary'}
          onClick={handleClickSummary}
        >
          SUMMARY
        </NavigationButton>
        <NavigationButton
          selected={visibleType === 'detail'}
          onClick={handleClickDetail}
        >
          DETAIL
        </NavigationButton>
      </NavigationWrapper>
      <TableWrapper>
        <AuthorityTable
          tableData={authorityTableData}
          visibleType={visibleType}
        />
      </TableWrapper>
    </Wrapper>
  );
};

export default memo(ServiceAuthorityTable);

const Wrapper = styled.div`
  position: relative;
`;

const NavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 28px 0;
  position: sticky;
  top: 68px;
  background-color: white;
  z-index: 1;

  ${mq.md} {
    top: 0;
    position: relative;
    padding: 14px 0;
  }
`;

type NavigationButtonProps = {
  selected: boolean;
};

const NavigationButton = styled.button<NavigationButtonProps>`
  appearance: none;
  border: none;
  background: none;
  font-family: futura-pt;
  padding: 0;
  line-height: 1;
  font-weight: 450;
  font-size: 20px;
  margin: 0 20px;
  letter-spacing: 0.2em;
  cursor: pointer;

  ${({ selected }) => {
    if (selected) {
      return css`
        color: #6eb6cc;
        font-weight: 550;

        &::after {
          display: block;
          width: 100%;
          height: 1px;
          margin-top: 2px;
          background-color: #6eb6cc;
          content: '';
        }
      `;
    }
  }}
`;

const TableWrapper = styled.div`
  ${mq.md} {
    overflow: auto;
  }
`;
