import { addEndSlash } from '@/util/endSlash';
import { useCallback, useState } from 'react';
import { documentAPI } from '..';
import { Navigation } from './types';
export * from './types';

export const useNavigation = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getNavigation = useCallback(
    async (language: string, path: string): Promise<Navigation | undefined> => {
      setIsLoading(true);

      try {
        // const resolvePath = resolve(i18n.language, path, 'navigation.json');
        const res = await documentAPI.get(
          `/${language}/${addEndSlash(path)}navigation.json`,
        );
        return res.data;
      } catch (err) {
        console.log(err);
        return;
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  return {
    isLoading,
    getNavigation,
  };
};
