import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ButtonColorType, ButtonType } from '../Button/types';
import ExternalLinkIcon from '@/assets/icon_external_link.svg?react';
import DownloadIcon from '@/assets/icon_download.svg?react';
import { memo } from 'react';

type Props = {
  buttonType: ButtonType;
  colorType: ButtonColorType;
  children?: React.ReactNode;
} & JSX.IntrinsicElements['button'];

const BaseButton: React.FC<Props> = ({
  buttonType,
  colorType,
  children,
  ...rest
}) => (
  <Wrapper {...rest} colorType={colorType}>
    <div className="text">{children}</div>
    <IconWrapper>
      {buttonType === 'external_link' ? (
        <ExternalLinkIcon />
      ) : buttonType === 'download' ? (
        <DownloadIcon />
      ) : (
        <BasicIcon />
      )}
    </IconWrapper>
  </Wrapper>
);

export default memo(BaseButton);

type WrapperProps = {
  colorType: ButtonColorType;
};

const Wrapper = styled.button<WrapperProps>`
  cursor: pointer;
  background: transparent;
  border: solid 2px #1f2933;
  border-radius: 16px;
  display: flex;
  padding: 12px 16px 12px 20px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  transition: color 450ms;

  ${({ colorType }) => {
    if (colorType === 'white') {
      return css`
        border-color: white;
      `;
    }
  }}

  &::before {
    content: '';
    display: block;
    width: 200%;
    height: calc(100% + 4px);
    background: linear-gradient(90deg, #1f2933, #1f2933, #1f293300);
    position: absolute;
    top: -2px;
    left: -1px;
    transform: translateX(-100%);
    transition: transform 450ms;
  }

  .text {
    position: relative;

    ${({ colorType }) => {
      if (colorType === 'white') {
        return css`
          color: white;
        `;
      }
    }}
  }

  &:hover {
    color: white;

    &::before {
      transform: translateX(0);
    }
  }
`;

const IconWrapper = styled.div`
  margin-left: 10px;
  position: relative;
`;

const BasicIcon = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #3098a6;
`;
