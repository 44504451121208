import { memo } from 'react';
import type { HeadingData } from '../HeadingMenu/types';
import { useHeadingMenuItem } from './useHeadingMenuItem';

type Props = {
  data: HeadingData | null;
};

const HeadingMenuItem: React.FC<Props> = ({ data }: Props) => {
  const { handleClickHref } = useHeadingMenuItem();

  if (!data) return null;

  return (
    <li className={`size-${data.size}`} data-heading-menu-id={data.id}>
      <a
        href={`#${data.id}`}
        onClick={(e) => {
          e.preventDefault();
          handleClickHref(`#${data.id}`);
          return false;
        }}
      >
        {data.text}
      </a>
    </li>
  );
};

export default memo(HeadingMenuItem);
