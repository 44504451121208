import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { getBranchLink } from '@/util/branch';
import type { ButtonColorType, ButtonType } from './types';
import BaseButton from '../BaseButton';

type Props = {
  children: React.ReactNode | string;
  href?: string;
  target?: string;
  buttonType?: ButtonType;
  isLink?: boolean;
  colorType?: ButtonColorType;
  download?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & JSX.IntrinsicElements['button'];

const Button: React.FC<Props> = ({
  buttonType = 'basic',
  isLink = false,
  colorType = 'default',
  download,
  children,
  href,
  target,
  ...rest
}: Props) => {
  if (href) {
    const branchLink = getBranchLink(href);
    if (download && download.length > 0) {
      // ダウンロードボタン
      return (
        <a
          css={css`
            text-decoration: none;
          `}
          href={branchLink}
          download={download}
        >
          <BaseButton buttonType={buttonType} colorType={colorType} {...rest}>
            {children}
          </BaseButton>
        </a>
      );
    }
    if (isLink) {
      // サイト内リンクボタン
      return (
        <Link
          css={css`
            text-decoration: none;
          `}
          to={branchLink}
        >
          <BaseButton buttonType={buttonType} colorType={colorType} {...rest}>
            {children}
          </BaseButton>
        </Link>
      );
    }
    // サイト外リンクボタン
    return (
      <a
        css={css`
          text-decoration: none;
        `}
        href={getBranchLink(href)}
        target={target}
      >
        <BaseButton buttonType={buttonType} colorType={colorType} {...rest}>
          {children}
        </BaseButton>
      </a>
    );
  }

  return (
    <BaseButton buttonType={buttonType} colorType={colorType} {...rest}>
      {children}
    </BaseButton>
  );
};

export default Button;
