import { useLangPath } from '@/i18n';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export const useFooter = () => {
  const langPath = useLangPath();
  const location = useLocation();

  const isTop = useMemo(
    () => !!matchPath(location.pathname, `${langPath}`),
    [langPath, location.pathname],
  );

  return {
    isTop,
  };
};
