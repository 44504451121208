import { useCallback } from 'react';

export const useHeadingMenuItem = () => {
  const handleClickHref = useCallback((href: string) => {
    href = decodeURIComponent(href);
    const targetElement = document.getElementById(href.replace('#', ''));
    if (targetElement) {
      const headerElement = document.querySelector('header[data-id="header"]');
      const headerHeight = headerElement ? headerElement.clientHeight : 0;
      const scrollTop =
        targetElement.getBoundingClientRect().top +
        window.scrollY -
        headerHeight;
      window.history.pushState(null, '', href);
      window.scrollTo({ top: scrollTop, behavior: 'smooth' });
    }
  }, []);

  return {
    handleClickHref,
  };
};
