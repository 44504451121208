import { siteDescription, siteTitle } from '@/util/appConfig';
import { useMemo } from 'react';

export const useHelmet = (
  title: string | undefined,
  description: string | undefined,
) => {
  const pageTitle = useMemo(
    () => (title ? `${title} | ${siteTitle}` : siteTitle),
    [title],
  );

  const pageDescription = useMemo(
    () => description ?? siteDescription,
    [description],
  );

  return {
    pageTitle,
    pageDescription,
  };
};
