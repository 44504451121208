import { drawerOpenAtom } from '@/global-states/drawer';
import { useLangPath } from '@/i18n';
import { savePath, userAtom } from '@/services/t4account/user';
import {
  authenticationManager,
  signInRedirect,
} from '@/util/authenticationManager';
import { executeWhenVisible } from '@tier4/webauto-auth-frontend-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import store from 'store2';
import React from 'react';
import throttle from 'lodash.throttle';

export const useHeader = () => {
  const langPath = useLangPath();
  const setDrawerOpen = useSetRecoilState(drawerOpenAtom);
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [accountMenu, setAccountMenu] = useState<React.ReactNode>();
  const user = useRecoilValue(userAtom);

  const isCurrent = useCallback(
    (pathname: string) => {
      const splitPath = location.pathname.split('/');
      const pageId = splitPath[1] === 'en' ? splitPath[2] : splitPath[1];
      return pathname === pageId ? 1 : 0;
    },
    [location.pathname],
  );

  const isTop = useMemo(
    () => !!matchPath(location.pathname, `${langPath}`),
    [langPath, location.pathname],
  );

  const handleClickLogin = useCallback(async () => {
    savePath();
    await executeWhenVisible(signInRedirect);
  }, []);

  const handleClickLogout = useCallback(async () => {
    store.session.remove('webauto_auth_js_bearer');
    await authenticationManager.userManager.signOutRedirect();
  }, []);

  const handleClickNav = useCallback(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  useEffect(() => {
    const handleScrollWindow = () => {
      let scrollTop = document.body.scrollTop;
      if (document.scrollingElement) {
        scrollTop = document.scrollingElement.scrollTop;
      }
      //  FIXME: Throttle に入れたいが react18 で react-use の useThrottle が機能していないので後々修正
      //  https://github.com/streamich/react-use/issues/2343
      if (scrollTop >= 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const throttled = throttle(handleScrollWindow, 100);
    window.addEventListener('scroll', throttled);
    return () => window.removeEventListener('scroll', throttled);
  }, []);

  return {
    user,
    isScrolled,
    accountMenu,
    setAccountMenu,
    isCurrent,
    isTop,
    handleClickLogin,
    handleClickLogout,
    handleClickNav,
    langPath,
  };
};
