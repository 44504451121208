import type { NavigationItem } from '@/services/documents';
import { removeEndSlash } from '@/util/endSlash';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useSideMenuNavigation = (isReleaseNoteListPage: boolean) => {
  const location = useLocation();

  const getHasChildren = useCallback(
    (item: NavigationItem) =>
      !!item.navigation?.items && !!item.navigation.items.length,
    [],
  );

  const getIsCurrent = useCallback(
    (link?: string) => {
      if (!link) return false;
      if (
        !isReleaseNoteListPage &&
        location.pathname.includes('/release-notes')
      ) {
        // User Manuals や Developer Guides 内の Release Notes ページ（一覧・詳細）の場合

        // 末尾にスラッシュがある場合削除
        const pathName = removeEndSlash(location.pathname);
        const splitPaths = pathName.split('/');
        // 最後のパス名が release-notes ではない場合詳細ページ
        const isDetailPage = splitPaths.at(-1) !== 'release-notes';
        const targetPathname = isDetailPage
          ? splitPaths.concat().slice(0, -1).join('/')
          : location.pathname;
        return link.includes(targetPathname);
      }

      return location.pathname.replaceAll('/', '') === link.replaceAll('/', '');
    },
    [location.pathname, isReleaseNoteListPage],
  );

  const openParentMenu = useCallback((element: HTMLElement | null) => {
    if (!element) return;
    element.classList.remove('hide');
    element.classList.add('open');
    if (element.previousElementSibling) {
      element.previousElementSibling.classList.add('open');
    }
    if (element.parentElement) {
      openParentMenu(element.parentElement.closest('ul.nest-menu'));
    }
  }, []);

  useEffect(() => {
    const currentMenu: HTMLElement | null = document.querySelector(
      '#sidemenu li[data-current="true"',
    );
    if (!currentMenu) return;
    openParentMenu(currentMenu.closest('ul.nest-menu'));
  }, [location.pathname, openParentMenu]);

  return {
    getHasChildren,
    getIsCurrent,
  };
};
