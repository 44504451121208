import { mq } from '@/breakpoints';
import NotFound from '@/pages/not_found';
import { ReleaseNotesData } from '@/services/documents';
import { getBranchLink } from '@/util/branch';
import styled from '@emotion/styled';
import { useMemo, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import Button from '../Button';
import Admonition from '../markdown/Admonition';
import { css } from '@emotion/react';
import { getContentsImagePath } from '@/util/contentsImagePath';
import type { ReleaseNoteList } from './types';
import { useReleaseNoteList } from './useReleaseNoteList';
import { PER_PAGE_NUM } from './constants';

type Props = {
  data: ReleaseNotesData;
};

const ReleaseNoteList: React.FC<Props> = ({ data }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    splitData,
    isNotFound,
    priorityLanguageData,
    onlyOtherLanguage,
    pageNo,
  } = useReleaseNoteList(data);

  const list = useMemo(() => {
    if (splitData.length === 0) return;
    return splitData.map((note) => {
      const img = getContentsImagePath(note.thumbnail);
      return (
        <li key={note.slug}>
          <Link to={getBranchLink(note.slug.replace('/ja/', '/'))}>
            <div className="note-wrapper">
              <div className="note-contents">
                <div className="note-date">
                  {DateTime.fromISO(note.published_at).toFormat(
                    t('date.format'),
                  )}
                </div>
                <div className="note-info">
                  <p className="note-title">{note.title}</p>
                  <p className="note-description">{note.description}</p>
                  {note.categories.length > 0 && (
                    <ul className="note-categories">
                      {note.categories.map((category) => (
                        <li className="note-category" key={category}>
                          {category}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div
                className="note-thumbnail"
                css={css`
                  background-image: url(${img});
                `}
              />
            </div>
          </Link>
        </li>
      );
    });
  }, [t, splitData]);

  const pagination = useMemo(() => {
    const isNext = priorityLanguageData.length > pageNo * PER_PAGE_NUM;
    const isPrev = pageNo > 1;
    if (!isNext && !isPrev) return;
    return (
      <div className="pagination">
        <div className="pagination-prev-wrapper">
          {isPrev && (
            <Button
              isLink
              href={getBranchLink(
                pageNo - 1 === 1
                  ? location.pathname
                  : `${location.pathname}?page=${pageNo - 1}`,
              )}
            >
              PREV
            </Button>
          )}
        </div>
        <div className="pagination-next-wrapper">
          {isNext && (
            <Button isLink href={`${location.pathname}?page=${pageNo + 1}`}>
              NEXT
            </Button>
          )}
        </div>
      </div>
    );
  }, [pageNo, priorityLanguageData, location.pathname]);

  if (!data) return null;

  return (
    <>
      {isNotFound ? (
        <NotFound />
      ) : (
        <article className="markdown-body">
          {onlyOtherLanguage && (
            <OtherLanguageAttentionWrapper>
              <Admonition
                type="caution"
                title={<Trans i18nKey="entry.other_language_only" />}
              />
            </OtherLanguageAttentionWrapper>
          )}
          <h1>Release Notes</h1>
          <ul className="release-notes">{list}</ul>
          {pagination}
        </article>
      )}
    </>
  );
};

export default memo(ReleaseNoteList);

const OtherLanguageAttentionWrapper = styled.div`
  margin-bottom: 60px;

  ${mq.md} {
    margin-bottom: 40px;
  }
`;
