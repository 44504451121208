import { mq } from '@/breakpoints';
import type { NavigationItem } from '@/services/documents';
import { getBranchLink } from '@/util/branch';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  item: NavigationItem | null;
  type: 'previous' | 'next';
};

const OrderNavigationLink: React.FC<Props> = ({ item, type }) => {
  const { t } = useTranslation();

  return (
    <ItemWrapper item={item} type={type}>
      <Link to={getBranchLink(item?.link ?? '')}>
        <span className="type">{t(`entry.order_navigation.${type}`)}</span>
        <span className="name">{item?.name}</span>
      </Link>
    </ItemWrapper>
  );
};
export default memo(OrderNavigationLink);

const ItemWrapper = styled.div<Props>`
  width: 50%;

  &:first-of-type {
    padding-right: 12px;
  }

  &:last-of-type {
    padding-left: 12px;
  }

  ${({ item }) => {
    if (!item) {
      return css`
        visibility: hidden;
        pointer-events: none;
      `;
    }
  }}

  a {
    text-decoration: none;
    text-align: ${({ type }) => (type === 'previous' ? 'left' : 'right')};

    span {
      display: block;

      &.type {
        color: ${({ theme }) => theme.textColor.primary};
        font-weight: normal;
        font-size: 14px;
        line-height: 1;
        margin-bottom: 1em;

        ${mq.md} {
          font-size: 13px;
        }
      }

      &.name {
        color: #3098a6;
        font-weight: 700;
        font-size: 16px;

        ${mq.md} {
          font-size: 14px;
        }
      }
    }

    &:hover {
      span {
        &.name {
          text-decoration: underline;
        }
      }
    }
  }
`;
