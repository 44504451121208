import { mq } from '@/breakpoints';
import type { NavigationItem } from '@/services/documents';
import { getBranchLink } from '@/util/branch';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import SideMenuTitle from '../SideMenuTitle';
import type { NestWrapperProps } from '../SideMenu/types';
import { useSideMenuNavigation } from './useSideMenuNavigation';

type Level = 'root' | 'sub' | 'nest';

type Props = {
  items: NavigationItem[] | undefined;
  level: Level;
  isReleaseNoteListPage: boolean;
  onClickNestWrapper: (e: React.MouseEvent<HTMLElement>) => void;
};

const SideMenuNavigation: React.FC<Props> = ({
  items,
  level,
  isReleaseNoteListPage,
  onClickNestWrapper,
}) => {
  const { getHasChildren, getIsCurrent } = useSideMenuNavigation(
    isReleaseNoteListPage,
  );

  if (!items) return null;

  // Root
  if (level === 'root') {
    return (
      <>
        {items.map((item, i) => {
          const hasChildren = getHasChildren(item);
          const isCurrent = getIsCurrent(item.link);
          return (
            <React.Fragment key={`root${i}`}>
              <SideMenuTitle
                current={isCurrent}
                isReleaseNoteListPage={!!isReleaseNoteListPage}
                className={
                  hasChildren
                    ? `nest ${item.navigation?.expand ? 'open' : ''}`
                    : ''
                }
                onClick={onClickNestWrapper}
              >
                {item.link ? (
                  <Link to={getBranchLink(`${item.link.replace('/ja', '')}`)}>
                    {hasChildren ? <span>{item.name}</span> : item.name}
                  </Link>
                ) : (
                  <span>{item.name}</span>
                )}
              </SideMenuTitle>
              {item.navigation?.items && (
                <RootList
                  className={`nest-menu ${item.navigation.expand ? '' : 'hide'}`}
                >
                  <SideMenuNavigation
                    level="sub"
                    items={item.navigation.items}
                    isReleaseNoteListPage={isReleaseNoteListPage}
                    onClickNestWrapper={onClickNestWrapper}
                  />
                </RootList>
              )}
              {!isReleaseNoteListPage && <Hr />}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  // Sub
  if (level === 'sub') {
    return (
      <>
        {items.map((item, i) => {
          const hasChildren = getHasChildren(item);
          const isCurrent = getIsCurrent(item.link);
          return (
            <RootListItem
              key={`root${i}`}
              data-current={isCurrent}
              current={isCurrent}
            >
              {item.link ? (
                <Link
                  className={
                    hasChildren
                      ? `nest ${item.navigation?.expand ? 'open' : ''}`
                      : ''
                  }
                  to={getBranchLink(`${item.link.replace('/ja', '')}`)}
                  onClick={onClickNestWrapper}
                >
                  {hasChildren ? <span>{item.name}</span> : item.name}
                </Link>
              ) : (
                <p
                  className={
                    hasChildren
                      ? `nest ${item.navigation?.expand ? 'open' : ''}`
                      : ''
                  }
                  onClick={onClickNestWrapper}
                >
                  {item.name}
                </p>
              )}
              {item.navigation?.items && (
                <SubList
                  className={`sub nest-menu ${
                    item.navigation.expand ? '' : 'hide'
                  }`}
                >
                  <SideMenuNavigation
                    level="nest"
                    items={item.navigation.items}
                    isReleaseNoteListPage={isReleaseNoteListPage}
                    onClickNestWrapper={onClickNestWrapper}
                  />
                </SubList>
              )}
            </RootListItem>
          );
        })}
      </>
    );
  }

  // Nest
  return (
    <>
      {items.map((item, i) => {
        const hasChildren = getHasChildren(item);
        const isCurrent = getIsCurrent(item.link);
        return (
          <SubListItem
            key={`nest${i}`}
            data-current={isCurrent}
            current={isCurrent}
          >
            {item.link ? (
              <Link
                className={
                  hasChildren
                    ? `nest ${item.navigation?.expand ? 'open' : ''}`
                    : ''
                }
                to={getBranchLink(`${item.link.replace('/ja', '')}`)}
                onClick={onClickNestWrapper}
              >
                {hasChildren ? <span>{item.name}</span> : item.name}
              </Link>
            ) : (
              <p
                className={
                  hasChildren
                    ? `nest ${item.navigation?.expand ? 'open' : ''}`
                    : ''
                }
                onClick={onClickNestWrapper}
              >
                {item.name}
              </p>
            )}
            {item.navigation?.items ? (
              <SubList
                className={`nest-menu ${item.navigation.expand ? '' : 'hide'}`}
              >
                <SideMenuNavigation
                  level="nest"
                  items={item.navigation.items}
                  isReleaseNoteListPage={isReleaseNoteListPage}
                  onClickNestWrapper={onClickNestWrapper}
                />
              </SubList>
            ) : null}
          </SubListItem>
        );
      })}
    </>
  );
};

export default React.memo(SideMenuNavigation);

const RootList = styled.ul`
  font-size: 13px;
  line-height: 1.5;
  list-style: none;
  padding: 0;
  margin: 0;

  &.hide {
    overflow: hidden;
    padding: 0;
    height: 0;
  }
`;

const RootListItem = styled.li<NestWrapperProps>`
  a,
  p {
    padding: 8px 16px 8px 32px;
    border-radius: 0 4px 4px 0;
    display: block;
  }

  p,
  a {
    margin: 0;

    &.nest {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      span {
        padding-right: 10px;
        flex: 1;
      }

      &::after {
        content: '';
        display: block;
        width: 6px;
        height: 8px;
        background-repeat: no-repeat;
        background-image: url(/assets/common/sidemenu/icon_arrow_right_white.svg);
        transition: transform 200ms ease-out;
      }

      &.open {
        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }

  ${({ current }) => {
    if (current) {
      return css`
        > a {
          font-weight: 700;
          background: rgba(31, 41, 51, 0.2);
        }
      `;
    }
  }}
`;

const Hr = styled.hr`
  margin: 16px 16px 0 24px;
  border: none;
  border-top: solid 1px rgba(255, 255, 255, 0.16);

  ${mq.md} {
    margin-bottom: 24px;
  }
`;

const SubList = styled.ul`
  padding: 4px 0 0 12px;
  list-style: none;

  &.hide {
    overflow: hidden;
    padding: 0;
    height: 0;
  }

  &.sub {
    margin: 0 4px 0 32px;
    border-left: solid 1px rgba(255, 255, 255, 0.16);
    padding-left: 0;

    .nest-menu {
      li {
        margin-left: 0;
      }
    }
  }
`;

const SubListItem = styled.li<NestWrapperProps>`
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 4px;
  margin-left: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  a,
  p {
    padding: 4px 12px !important;
    border-radius: 4px;
    display: block;
  }

  ${({ current }) => {
    if (current) {
      return css`
        > a {
          font-weight: 700;
          background: rgba(31, 41, 51, 0.2);
        }
      `;
    }
  }}
`;
