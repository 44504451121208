import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import { mq } from '@/breakpoints';
import { Navigation } from '@/services/documents/navigation/types';
import { getBranchLink } from '@/util/branch';
import { useLangPath } from '@/i18n';
import { useSideMenu } from './useSideMenu';
import SideMenuNavigation from '../SideMenuNavigation';
import SideMenuTitle from '../SideMenuTitle';

type Props = {
  data: Navigation | undefined;
  // https://domain/release-notes 以下かどうか
  isReleaseNoteListPage?: boolean;
};

const SideMenu: React.FC<Props> = ({
  data,
  isReleaseNoteListPage = false,
}: Props) => {
  const langPath = useLangPath();
  const { open, getIsCurrent, handleClickBurger, handleClickNestWrapper } =
    useSideMenu(data, isReleaseNoteListPage);

  return (
    <Wrapper id="sidemenu" open={open ? 1 : 0}>
      <Sticky>
        <BurgerIcon onClick={handleClickBurger}>
          <span />
          <span />
        </BurgerIcon>
        <MenuWrapper open={open}>
          {isReleaseNoteListPage && (
            <SideMenuTitle current={getIsCurrent(`${langPath}release-notes`)}>
              <Link to={getBranchLink(`${langPath}release-notes`)}>
                RELEASE NOTES
              </Link>
            </SideMenuTitle>
          )}
          {data && (
            <SideMenuNavigation
              level="root"
              items={data.items}
              isReleaseNoteListPage={isReleaseNoteListPage}
              onClickNestWrapper={handleClickNestWrapper}
            />
          )}
        </MenuWrapper>
      </Sticky>
    </Wrapper>
  );
};

export default SideMenu;

type MenuWrapperProps = {
  open: boolean;
};

const MenuWrapper = styled.div<MenuWrapperProps>`
  ${mq.md} {
    padding-top: 10px;

    ${({ open }) => {
      if (!open) {
        return css`
          pointer-events: none;
          opacity: 0;
        `;
      }
    }}
  }
`;

const BurgerIcon = styled.div`
  display: none;

  ${mq.md} {
    display: block;
    position: absolute;
    top: 1px;
    right: 24px;
    padding: 15px;
    transition: top 200ms;

    span {
      display: block;
      position: relative;

      &::before,
      &::after {
        width: 12px;
        height: 1.5px;
        border-radius: 0.75px;
        background-color: white;
        margin-bottom: 2.5px;
        display: block;
        content: '';
      }

      &:last-of-type {
        &::before {
          margin-bottom: 0;
        }

        &::after {
          display: none;
        }
      }
    }
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 68px;
  padding: 0 8px 36px 0;
  height: calc(100vh - 68px);
  overflow: auto;

  ${mq.md} {
    position: static;
    top: 0;
    padding: 0;
    height: calc(100vh);
  }
`;

type WrapperProps = {
  open: 0 | 1;
};

const Wrapper = styled.div<WrapperProps>`
  width: 260px;
  color: white;
  box-sizing: border-box;
  font-family: 'Inter';
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    background-attachment: fixed !important;
    width: 280px;
    height: 100%;

    ${mq.md} {
      display: none;
    }
  }

  body[data-type='webauto'] & {
    &::before {
      background: linear-gradient(180deg, #3098a6 0px, #214772 800px);
    }

    ${mq.md} {
      background: linear-gradient(270deg, #214772 0%, #3098a6 100%);
    }
  }

  body[data-type='pilotauto'] & {
    &::before {
      background: linear-gradient(225.93deg, #073a86 0%, #2d849a 101.67%);
    }

    ${mq.md} {
      background: linear-gradient(225.93deg, #073a86 0%, #2d849a 101.67%);
    }
  }

  a {
    color: white;
    text-decoration: none;
  }

  ${mq.md} {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    position: sticky;
    top: 0;
    z-index: 10;
    transition:
      padding 200ms,
      height 200ms;
    height: 42px;
    overflow: hidden;

    ${({ open }) => {
      if (open) {
        return css`
          padding-top: 24px;
          height: 100vh;

          ${BurgerIcon} {
            top: 11px;
          }
        `;
      }
    }}
  }
`;
