import { drawerOpenAtom } from '@/global-states/drawer';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

export const useDrawer = () => {
  const { i18n } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenAtom);

  const handleClickLanguage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const lang = event.currentTarget.dataset.lang;
      i18n.changeLanguage(lang);
    },
    [i18n],
  );

  const handleClickClose = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  return {
    drawerOpen,
    handleClickLanguage,
    handleClickClose,
  }
};
