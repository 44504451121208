import type { Navigation, NavigationItem } from '@/services/documents';
import { removeEndSlash } from '@/util/endSlash';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useOrderNavigation = (navigation: Navigation) => {
  const location = useLocation();

  const currentPagePath = useMemo(() => location.pathname, [location.pathname]);

  const allLinkItems = useMemo(() => {
    const arr: NavigationItem[] = [];
    const setItems = (items: NavigationItem[]) => {
      items.forEach((item) => {
        if (item.link) arr.push(item);
        if (item.navigation?.items) {
          setItems(item.navigation.items);
        }
      });
    };
    setItems(navigation.items);
    return arr;
  }, [navigation]);

  const currentPageIndex = useMemo(
    () =>
      allLinkItems.findIndex(
        (item) => removeEndSlash(currentPagePath) === item.link,
      ),
    [allLinkItems, currentPagePath],
  );

  const previousNavigationItem = useMemo(
    () => (currentPageIndex <= 0 ? null : allLinkItems[currentPageIndex - 1]),
    [currentPageIndex, allLinkItems],
  );

  const nextNavigationItem = useMemo(
    () =>
      currentPageIndex < 0 || currentPageIndex === allLinkItems.length - 1
        ? null
        : allLinkItems[currentPageIndex + 1],
    [currentPageIndex, allLinkItems],
  );

  return {
    previousNavigationItem,
    nextNavigationItem,
  };
};
