import { sideMenuOpenAtom } from '@/global-states/sideMenu';
import type { Navigation } from '@/services/documents';
import { removeEndSlash } from '@/util/endSlash';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export const useSideMenu = (
  data: Navigation | undefined,
  isReleaseNoteListPage: boolean | undefined,
) => {
  const [open, setOpen] = useRecoilState(sideMenuOpenAtom);
  const location = useLocation();

  const getIsCurrent = useCallback(
    (link?: string) => {
      if (!link) return false;
      if (
        !isReleaseNoteListPage &&
        location.pathname.includes('/release-notes')
      ) {
        // User Manuals や Developer Guides 内の Release Notes ページ（一覧・詳細）の場合

        // 末尾にスラッシュがある場合削除
        const pathName = removeEndSlash(location.pathname);
        const splitPaths = pathName.split('/');
        // 最後のパス名が release-notes ではない場合詳細ページ
        const isDetailPage = splitPaths.at(-1) !== 'release-notes';
        const targetPathname = isDetailPage
          ? splitPaths.concat().slice(0, -1).join('/')
          : location.pathname;
        return link.includes(targetPathname);
      }

      return location.pathname.replaceAll('/', '') === link.replaceAll('/', '');
    },
    [location.pathname, isReleaseNoteListPage],
  );

  const handleClickBurger = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, [setOpen]);

  const handleClickNestWrapper = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const element = e.currentTarget as HTMLParagraphElement;
      if (!element.classList.contains('nest')) return;
      const nextElement = element.nextElementSibling;
      if (nextElement) {
        const ulElement = nextElement as HTMLUListElement;
        if (ulElement.classList.contains('hide')) {
          ulElement.classList.remove('hide');
          element.classList.add('open');
        } else {
          ulElement.classList.add('hide');
          element.classList.remove('open');
        }
      }
    },
    [],
  );

  const openParentMenu = useCallback((element: HTMLElement | null) => {
    if (!element) return;
    element.classList.remove('hide');
    element.classList.add('open');
    if (element.previousElementSibling) {
      element.previousElementSibling.classList.add('open');
    }
    if (element.parentElement) {
      openParentMenu(element.parentElement.closest('ul.nest-menu'));
    }
  }, []);

  useEffect(() => {
    if (!data) return;
    const currentMenu: HTMLElement | null = document.querySelector(
      '#sidemenu li[data-current="true"',
    );
    if (!currentMenu) return;
    openParentMenu(currentMenu.closest('ul.nest-menu'));
  }, [location.pathname, data, openParentMenu]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname, setOpen]);

  return {
    open,
    openParentMenu,
    getIsCurrent,
    handleClickBurger,
    handleClickNestWrapper,
  };
};
