import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import type { ReleaseNoteList } from './types';
import type { ReleaseNotesData } from '@/services/documents';
import { PER_PAGE_NUM } from './constants';

export const useReleaseNoteList = (data: ReleaseNotesData) => {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [onlyOtherLanguage, setOnlyOtherLanguage] = useState(false);

  const releaseNotes = useMemo(() => {
    const releaseNoteList: ReleaseNoteList = {
      ja: [],
      en: [],
    };
    if (data.ja) {
      releaseNoteList.ja = data.ja.release_notes;
    }
    if (data.en) {
      releaseNoteList.en = data.en.release_notes;
    }
    return releaseNoteList;
  }, [data]);

  const pageNo = useMemo(() => {
    const no = Number(searchParams.get('page'));
    if (Number.isNaN(no) || no <= 0) return 1;
    return no;
  }, [searchParams]);

  const priorityLanguageData = useMemo(() => {
    const otherLanguage = i18n.language === 'ja' ? 'en' : 'ja';
    let returnData = releaseNotes[i18n.language as 'ja' | 'en'];
    if (returnData.length === 0) {
      returnData = releaseNotes[otherLanguage];
      setOnlyOtherLanguage(true);
    }
    return returnData;
  }, [releaseNotes, i18n.language]);

  const splitData = useMemo(() => {
    if (priorityLanguageData) {
      const length = pageNo * PER_PAGE_NUM;
      const returnData = priorityLanguageData
        .concat()
        .slice(
          pageNo * PER_PAGE_NUM - PER_PAGE_NUM,
          length > priorityLanguageData.length
            ? priorityLanguageData.length
            : length,
        );
      return returnData;
    }
    return [];
  }, [priorityLanguageData, pageNo]);

  const isNotFound = useMemo(
    () => releaseNotes.ja.length === 0 && releaseNotes.en.length === 0,
    [releaseNotes.ja, releaseNotes.en],
  );

  return {
    pageNo,
    splitData,
    isNotFound,
    onlyOtherLanguage,
    priorityLanguageData,
  };
};
