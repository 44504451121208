import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { mq } from '@/breakpoints';
import { useLangPath } from '@/i18n';
import { getBranchLink } from '@/util/branch';
import { isPilotAuto, isWebAuto } from '@/util/appConfig';
import { useDrawer } from './useDrawer';

const Drawer = () => {
  const { i18n } = useTranslation();
  const langPath = useLangPath();
  const { drawerOpen, handleClickLanguage, handleClickClose } = useDrawer();

  return (
    <Wrapper className={drawerOpen ? 'open' : ''}>
      <Bg onClick={handleClickClose} />
      <Nav>
        <NavHeader>
          <LanguageSelect>
            <LanguageButton
              current={i18n.language === 'ja'}
              data-lang="ja"
              onClick={handleClickLanguage}
            >
              JA
            </LanguageButton>
            <LanguageButton
              current={i18n.language === 'en'}
              data-lang="en"
              onClick={handleClickLanguage}
            >
              EN
            </LanguageButton>
          </LanguageSelect>
          <CloseButton onClick={handleClickClose}>
            <CloseIconWrapper>
              <span />
              <span />
            </CloseIconWrapper>
          </CloseButton>
        </NavHeader>
        <ItemWrapper>
          {/* <SearchWrapper>
            <SearchInput placeholder="Search" type="search" />
          </SearchWrapper> */}
          <NavList>
            <NavListItem>
              <Link to={getBranchLink(langPath)} onClick={handleClickClose}>
                HOME
              </Link>
            </NavListItem>
            {isWebAuto && (
              <>
                <NavListItem>
                  <Link
                    to={getBranchLink(`${langPath}user-manuals/`)}
                    onClick={handleClickClose}
                  >
                    USER MANUALS
                  </Link>
                </NavListItem>
                <NavListItem>
                  <Link
                    to={getBranchLink(`${langPath}developers-guides/`)}
                    onClick={handleClickClose}
                  >
                    DEVELOPER&apos;S GUIDES
                  </Link>
                </NavListItem>
              </>
            )}
            {isPilotAuto && (
              <NavListItem>
                <Link
                  to={getBranchLink(`${langPath}reference-design/`)}
                  onClick={handleClickClose}
                >
                  REFERENCE DESIGN
                </Link>
              </NavListItem>
            )}
            <NavListItem>
              <Link
                to={getBranchLink(`${langPath}release-notes/`)}
                onClick={handleClickClose}
              >
                RELEASE NOTES
              </Link>
            </NavListItem>
            <NavListItem>
              <Link
                to={getBranchLink(`${langPath}faqs/`)}
                onClick={handleClickClose}
              >
                FAQ &amp; CONTACTS
              </Link>
            </NavListItem>
          </NavList>
          <NavFooter>
            <FooterListWrapper>
              <LinkList>
                <LinkListItem>
                  <a
                    href="https://twitter.com/tier_iv_japan"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TWITTER
                  </a>
                </LinkListItem>
                <LinkListItem>
                  <a
                    href="https://www.instagram.com/tier_iv/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    INSTAGRAM
                  </a>
                </LinkListItem>
                <LinkListItem>
                  <a
                    href="https://www.facebook.com/TIERIV/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    FACEBOOK
                  </a>
                </LinkListItem>
                <LinkListItem>
                  <a
                    href="https://www.linkedin.com/company/tier-iv-inc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LINKEDIN
                  </a>
                </LinkListItem>
                <LinkListItem>
                  <a
                    href="https://www.youtube.com/channel/UC_rY-0gjUuzeqSWakSOUblg/featured"
                    target="_blank"
                    rel="noreferrer"
                  >
                    YOUTUBE
                  </a>
                </LinkListItem>
                <LinkListItem>
                  <a
                    href="https://medium.com/tier-iv-tech-blog/tagged/tech-blog"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TECH BLOG
                  </a>
                </LinkListItem>
              </LinkList>
              <SecondListWrapper>
                <LinkList>
                  <LinkListItem>
                    <a
                      href={`https://account.tier4.jp/terms${
                        i18n.language === 'en' ? '' : '?lang=ja'
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      TERM
                    </a>
                  </LinkListItem>
                  <LinkListItem>
                    <a
                      href={`https://tier4.jp/${
                        i18n.language === 'en' ? 'en/' : ''
                      }privacy/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      PRIVACY
                    </a>
                  </LinkListItem>
                  {isPilotAuto ? (
                    <>
                      <LinkListItem>
                        <a
                          href={`https://web.auto/${
                            i18n.language === 'en' ? '' : 'ja/'
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Web.Auto
                        </a>
                      </LinkListItem>
                      <LinkListItem>
                        <a
                          href={`https://docs.web.auto/${
                            i18n.language === 'en' ? 'en/' : ''
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Web.Auto DOCS
                        </a>
                      </LinkListItem>
                    </>
                  ) : (
                    <>
                      <LinkListItem>
                        <a
                          href={`https://pilot.auto/${
                            i18n.language === 'en' ? '' : 'ja/'
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Pilot.Auto
                        </a>
                      </LinkListItem>
                      <LinkListItem>
                        <a
                          href={`https://docs.pilot.auto/${
                            i18n.language === 'en' ? 'en/' : ''
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Pilot.Auto DOCS
                        </a>
                      </LinkListItem>
                    </>
                  )}
                </LinkList>
                <Copyright>
                  <a
                    href={`https://tier4.jp/${
                      i18n.language === 'en' ? 'en/' : ''
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    &copy; TIER IV, INC.
                  </a>
                </Copyright>
              </SecondListWrapper>
            </FooterListWrapper>
            <ServiceSiteLink
              href={`https://${
                isPilotAuto ? 'pilot.auto' : 'web.auto'
              }/${i18n.language === 'en' ? '' : 'ja/'}`}
              target="_blank"
              rel="noreferrer"
            >
              <div>
                {isPilotAuto ? 'Pilot.Auto' : 'Web.Auto'}
                <img src="/assets/common/drawer/icon_new_window.svg" />
              </div>
            </ServiceSiteLink>
          </NavFooter>
        </ItemWrapper>
      </Nav>
    </Wrapper>
  );
};

export default Drawer;

const Bg = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(31, 41, 51, 0.16);
`;

const Nav = styled.nav`
  background-color: #1f2933;
  position: absolute;
  right: 0;
  top: 0;
  width: 400px;
  height: 100%;

  ${mq.md} {
    width: 100%;
  }
`;

const NavHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LanguageSelect = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
`;

type LanguageButtonProps = {
  current: boolean;
};

const LanguageButton = styled.button<LanguageButtonProps>`
  cursor: pointer;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.32);
  font-size: 12px;
  line-height: 1;
  padding: 24px;
  transition: color 200ms;

  &:first-of-type {
    padding-left: 12px;
    padding-right: 0;

    &::after {
      margin-left: 12px;
      vertical-align: middle;
      content: '';
      display: inline-block;
      width: 1px;
      height: 10px;
      background-color: white;
    }
  }

  &:last-of-type {
    padding-left: 12px;
    padding-right: 12px;
  }

  ${({ current }) => {
    if (current) {
      return css`
        color: white;
        text-decoration: underline;
      `;
    }
  }}

  &:hover {
    color: white;
  }
`;

const CloseIconWrapper = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  flex-wrap: wrap;

  span {
    display: block;
    width: 12px;
    position: relative;

    &::before,
    &::after {
      display: block;
      width: 4px;
      height: 1px;
      content: '';
      background-color: white;
      position: relative;
      transition:
        top 200ms,
        left 200ms;
    }

    &:first-of-type {
      &::before {
        transform: rotate(45deg);
        left: 3px;
        top: 3px;
      }
      &::after {
        top: 2px;
        left: 9px;
        transform: rotate(135deg);
      }
    }

    &:last-of-type {
      &::before {
        transform: rotate(-45deg);
        top: 3px;
        left: 3px;
      }
      &::after {
        transform: rotate(-135deg);
        top: 2px;
        left: 9px;
      }
    }
  }
`;

const CloseButton = styled.button`
  cursor: pointer;
  width: 88px;
  height: 68px;
  background-color: #28323b;
  border: none;
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    ${CloseIconWrapper} {
      span {
        &:first-of-type {
          &::before {
            top: 1px;
            left: 1px;
          }

          &::after {
            top: 0;
            left: 11px;
          }
        }

        &:last-of-type {
          &::before {
            top: 5px;
            left: 1px;
          }
          &::after {
            top: 4px;
            left: 11px;
          }
        }
      }
    }
  }

  ${mq.md} {
    height: 56px;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 96px 40px 80px;
  height: calc(100% - 68px);
  box-sizing: border-box;

  ${mq.md} {
    padding-top: 70px;
    overflow: auto;
    height: calc(100% - 56px);
  }
`;

// const SearchWrapper = styled.div`
//   position: relative;

//   button {
//     display: block;
//     background: url(/assets/common/index/search_arrow.svg);
//     width: 12px;
//     height: 12px;
//     position: absolute;
//     top: calc(50% - 6px);
//     right: 24px;
//     border: none;
//   }
// `;

// const SearchInput = styled.input`
//   background-color: rgba(255, 255, 255, 0.04);
//   border: none;
//   border-radius: 16px;
//   font-size: 12px;
//   width: 100%;
//   box-sizing: border-box;
//   padding: 16px;
//   font-family: 'Inter';
//   line-height: 1;
//   position: relative;
//   color: white;

//   &::placeholder {
//     color: #fbfbfb;
//     opacity: 0.5;
//   }
// `;

const NavList = styled.ul`
  padding: 0;
  margin: 70px 0 112px;
  list-style: none;

  ${mq.md} {
    margin: 0;
  }
`;

const NavListItem = styled.li`
  line-height: 1;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    color: white;
    text-decoration: none;
    font-weight: 700;
    font-size: 24px;
    transition: opacity 200ms;

    &:hover {
      opacity: 0.32;
    }
  }
`;

const NavFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const FooterListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`;

const LinkList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100px;
`;

const LinkListItem = styled.li`
  font-size: 11px;
  line-height: 1;
  margin-bottom: 12px;
  color: white;

  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    color: white;
    text-decoration: none;
    transition: opacity 200ms;

    &:hover {
      opacity: 0.32;
    }
  }

  ${mq.md} {
    margin-bottom: 20px;
  }
`;

const SecondListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Copyright = styled.p`
  line-height: 1;
  margin: 0;
  font-size: 11px;

  a {
    color: rgba(255, 255, 255, 0.4);
    text-decoration: none;
  }
`;

const ServiceSiteLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border-radius: 12px;
  font-size: 10px;
  color: white;
  text-decoration: none;
  flex-wrap: wrap;
  line-height: 1;
  position: relative;
  overflow: hidden;
  height: 100px;

  ${mq.md} {
    height: 103px;
  }

  &::before,
  &::after {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
    position: absolute;
  }

  &::before {
    z-index: 1;
    transition: opacity 500ms;
  }

  &::after {
    z-index: 0;
  }

  body[data-type='webauto'] & {
    &::before {
      background: linear-gradient(328.9deg, #214772 0%, #3098a6 100%);
    }

    &::after {
      background: linear-gradient(328.9deg, #214772 20%, #3098a6 120%);
    }
  }

  body[data-type='pilotauto'] & {
    &::before {
      background: linear-gradient(225.93deg, #073a86 0%, #2d849a 101.67%);
    }

    &::after {
      background: linear-gradient(225.93deg, #073a86 20%, #2d849a 120%);
    }
  }

  &:hover {
    &::before {
      opacity: 0;
    }
  }

  div {
    text-align: center;
    position: relative;
    z-index: 10;
  }

  img {
    display: block;
    margin: 8px auto 0;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  width: 0;
  height: 100vh;
  z-index: 10000;
  top: 0;
  right: 0;
  font-family: 'Inter';
  pointer-events: none;
  overflow: hidden;
  transition: width 500ms ease-in-out;
  // transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

  &.open {
    pointer-events: auto;
    width: 100vw;
  }

  ${mq.md} {
    transition: transform 500ms ease-in-out;
    width: 100%;
    transform: translateX(100%);

    &.open {
      transform: translateX(0);
    }
  }
`;
