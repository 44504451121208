import { mq } from '@/breakpoints';
import type { Navigation } from '@/services/documents';
import styled from '@emotion/styled';
import OrderNavigationLink from '../OrderNavigationLink';
import { useOrderNavigation } from './useOrderNavigation';
import { memo } from 'react';

type Props = {
  navigation: Navigation;
};

const OrderNavigation: React.FC<Props> = ({ navigation }) => {
  const { previousNavigationItem, nextNavigationItem } =
    useOrderNavigation(navigation);

  return (
    <Wrapper>
      <OrderNavigationLink type="previous" item={previousNavigationItem} />
      <OrderNavigationLink type="next" item={nextNavigationItem} />
    </Wrapper>
  );
};

export default memo(OrderNavigation);

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  ${mq.md} {
    margin-top: 30px;
  }
`;
