import { mq } from '@/breakpoints';
import type { Navigation } from '@/services/documents';
import { getBranchLink } from '@/util/branch';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useBreadcrumbs } from './useBreadcrumbs';
import { memo } from 'react';

type Props = {
  navigation: Navigation;
};

const Breadcrumbs: React.FC<Props> = ({ navigation }) => {
  const { ancestors } = useBreadcrumbs(navigation);

  return (
    <Wrapper>
      {ancestors?.map((item) => (
        <li key={item.name}>
          {item.link ? (
            <Link to={getBranchLink(item.link)}>{item.name}</Link>
          ) : (
            item.name
          )}
        </li>
      ))}
    </Wrapper>
  );
};

export default memo(Breadcrumbs);

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 30px;
  font-size: 14px;
  list-style: none;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1;
  align-items: flex-start;
  padding-left: 44px;
  text-indent: -20px;
  background: url(/assets/common/common/icon_home.svg) no-repeat;
  background-position: left -1px;
  background-size: 16px;

  ${mq.md} {
    margin-bottom: 20px;
  }

  li {
    vertical-align: top;
    margin: 0 0 10px;

    &::after {
      content: '';
      background: url(/assets/common/common/icon_nav_arrow_right.svg) no-repeat;
      width: 20px;
      height: 20px;
      display: inline-block;
      margin: -3px 24px 0 8px;
    }
    &:last-of-type {
      &::after {
        content: none;
        margin: 0;
      }
    }

    a {
      color: ${({ theme }) => theme.textColor.primary};
      text-decoration: none;
    }
  }
`;
