import PlantUmlEncoder from 'plantuml-encoder';
import React, { useCallback, useMemo } from 'react';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import rehypeParse from 'rehype-parse';
import rehypePrism from 'rehype-prism-plus';
import rehypeReact, { type Options } from 'rehype-react';
import rehypeStringify from 'rehype-stringify';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeSlug from 'rehype-slug';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified } from 'unified';
import * as prod from 'react/jsx-runtime';
import Admonition from '../components/common/markdown/Admonition';
import { AdmonitionType } from '../components/common/markdown/Admonition/Admonition';
import Button from '../components/common/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getBranchLink, getPageBranchParam } from './branch';
import path from 'path';
import ServiceAuthorityTable from '@/components/common/markdown/ServiceAuthorityTable';
import type { Element } from 'hast';
import { documentAPIOrigin, isBranchPreview } from './appConfig';

// Admonition 構文を HTML要素に変換
const convertAdmonitions = (markdown: string, type: AdmonitionType) => {
  const regexp = String.raw`^:::(?:${type})([\s\S]*?):::$`;
  return markdown.replaceAll(new RegExp(regexp, 'gm'), (e) => {
    const title = e
      .match(String.raw`:::${type} ([\s\S]*?\n)`)
      ?.map((s) => s.replace(/\n/, ''))[1];
    const firstIndex = e.indexOf(`\n`);
    const lastIndex = e.lastIndexOf('\n');
    const code = e.substring(firstIndex, lastIndex);
    const replaceElement = `<div class="admonition ${type}" ${
      title && `title="${title}"`
    }>\n${code}\n</div>`;
    return replaceElement;
  });
};

// マークダウン構文を HTML 要素に変換
export const markdownToString = (
  markdown: string,
  slug: string,
  footnoteLabel: string,
) => {
  let replaceMd = markdown;

  // PlantUML を画像に変換
  replaceMd = replaceMd.replaceAll(
    /^```(?:plantuml)\n([\s\S]*?)```$/gm,
    (e) => {
      const firstIndex = e.indexOf(`\n`);
      const lastIndex = e.lastIndexOf('\n');
      const code = e.substring(firstIndex, lastIndex);
      const replaceElement = `<div class="plantuml">
        <img src="https://www.plantuml.com/plantuml/svg/${PlantUmlEncoder.encode(
          code,
        )}"></div>`;
      return replaceElement;
    },
  );

  // Admonition 変換
  replaceMd = convertAdmonitions(replaceMd, 'note');
  replaceMd = convertAdmonitions(replaceMd, 'info');
  replaceMd = convertAdmonitions(replaceMd, 'tip');
  replaceMd = convertAdmonitions(replaceMd, 'caution');
  replaceMd = convertAdmonitions(replaceMd, 'danger');

  const contentString = unified()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkRehype, { allowDangerousHtml: true, footnoteLabel })
    .use(rehypeSlug)
    .use(rehypeAutolinkHeadings, {
      behavior: 'append',
      content: {
        type: 'element',
        tagName: 'span',
        properties: { className: ['icon-header-link'] },
        children: [
          {
            type: 'text',
            value: '#',
          },
        ],
      },
    })
    .use(rehypeExternalLinks, { rel: ['nofollow', 'noreferrer'] })
    .use(rehypePrism, { ignoreMissing: true, showLineNumbers: true })
    .use(rehypeStringify, { allowDangerousHtml: true })
    .processSync(replaceMd)
    .toString()
    .replaceAll('<pre>', '<pre class="language-default">');

  const branchParam = getPageBranchParam();
  const apiOrigin =
    isBranchPreview && branchParam
      ? `${documentAPIOrigin}/${branchParam}`
      : documentAPIOrigin;

  const imgsrcList = [];
  const rex = /<*img[^>]*src *= *["']?([^"']*)/g;
  let match;
  while ((match = rex.exec(contentString))) {
    imgsrcList.push(match[1]);
  }
  let replaceImgContentString = contentString;
  imgsrcList.forEach((imgsrc) => {
    const isExternaPath = imgsrc.match(/^https?/);
    if (!isExternaPath && !path.isAbsolute(imgsrc)) {
      const replacePath = `${apiOrigin}${path.resolve(slug, imgsrc)}`;
      replaceImgContentString = replaceImgContentString.replaceAll(
        `"${imgsrc}`,
        `"${replacePath}`,
      );
    }
  });

  return replaceImgContentString;
};

type RouterLinkParam = {
  href?: string;
  className?: string;
  download?: string;
  target?: string;
  children?: React.ReactNode;
};

export const useLink = () => {
  const { i18n } = useTranslation();

  const getLinkElement = useCallback(
    ({ href, className, children }: RouterLinkParam) => {
      if (!href) return null;
      return (
        <Link
          to={getBranchLink(href)}
          className={className}
          onClick={() => {
            // リンク先が別言語の場合
            if (href.indexOf('/') === 0) {
              if (i18n.language === 'ja') {
                // ja -> en
                if (href.includes('/en/')) {
                  i18n.changeLanguage('en');
                }
              } else {
                // en -> ja
                if (!href.includes('/en/')) {
                  i18n.changeLanguage('ja');
                }
              }
            }
          }}
        >
          {children}
        </Link>
      );
    },
    [i18n],
  );

  const getLink = useCallback(
    (params: RouterLinkParam) => {
      const { href, download, className, target, children } = params;
      const isHashLink = href && href.indexOf('#') === 0;
      const isMailLink = href && href.indexOf('mailto:') >= 0;
      const isExternalLink = href && href.match(/^https?/);
      const isDownloadLink = !!download;
      const isRelativePath =
        href && href.indexOf('/') > 0 && href.indexOf('.') === 0;
      const isRootPath = !isHashLink && !isExternalLink && !isRelativePath;
      const isFileLink =
        (isRelativePath || isRootPath) &&
        href &&
        path.extname(href).length > 0 &&
        !href.includes('index.ja.md') &&
        !href.includes('index.en.md');
      const getHash = () => {
        if (!href || !href.includes('#')) return '';
        return href.substring(href.indexOf('#'), href.length);
      };
      const getQuery = () => {
        if (!href || !href.includes('?')) return '';
        return href.substring(href.indexOf('?', href.length));
      };

      const getHref = () => {
        if (!href) return '';
        // メールリンク
        if (isMailLink) return href;
        // ハッシュリンク
        if (isHashLink) return href;
        // 外部リンク
        if (isExternalLink) return href;
        // ダウンロードリンク
        if (isDownloadLink) return href;
        // 相対パス or ルートパス 且つ ファイルへのリンク
        if (isFileLink) {
          const branchParam = getPageBranchParam();
          const apiOrigin =
            isBranchPreview && branchParam
              ? `${documentAPIOrigin}/${branchParam}`
              : documentAPIOrigin;

          return `${apiOrigin}${
            i18n.language === 'ja' ? `/${i18n.language}` : ''
          }${path.resolve(window.location.pathname, href)}`;
        }
        // 相対パス
        if (isRelativePath) {
          const hash = getHash();
          const query = getQuery();
          const pathName = window.location.pathname;
          const convertedAbsolutePath = path.resolve(pathName, href);
          let pathNames = convertedAbsolutePath.split('/');
          // 現在が英語の場合
          if (i18n.language === 'en') {
            // 日本語ファイルへのパス
            if (convertedAbsolutePath.includes('index.ja.md')) {
              pathNames = pathNames.filter((name) => name !== 'en');
              const joinedPath = pathNames.join('/').replace('index.ja.md', '');
              return `${joinedPath}${query}${hash}`;
            }
            return convertedAbsolutePath.replace('index.en.md', '');
          }
          // 現在が日本語の場合
          if (convertedAbsolutePath.includes('index.en.md')) {
            const joinedPath = pathNames.join('/').replace('index.en.md', '');
            return `/en${joinedPath}${query}${hash}`;
          }
          return convertedAbsolutePath.replace('index.ja.md', '');
        }
        // ルートパス
        if (isRootPath) {
          if (href.includes('.ja.md')) {
            // 日本語パス
            return href.replace('index.ja.md', '');
          }
          if (href.includes('.en.md')) {
            // 英語パス
            return `/en${href.replace('index.en.md', '')}`;
          }
          return href;
        }
        return href;
      };

      const linkPath = getHref();

      if (className) {
        // Button
        if (className.includes('button') && href) {
          if (className.includes('external')) {
            return (
              <Button
                buttonType="external_link"
                href={linkPath}
                target={target}
              >
                {children}
              </Button>
            );
          }
          if (className.includes('download')) {
            return (
              <Button buttonType="download" href={linkPath} download={href}>
                {children}
              </Button>
            );
          }
          return (
            <Button href={linkPath} isLink>
              {children}
            </Button>
          );
        }

        if (isExternalLink && target !== '_blank') {
          return (
            <a
              {...params}
              href={linkPath}
              target="_blank"
              rel="noopener noreferrer"
            />
          );
        }

        if (
          isMailLink ||
          isHashLink ||
          isExternalLink ||
          isDownloadLink ||
          isFileLink
        ) {
          return <a {...params} href={linkPath} />;
        }
        return getLinkElement({ ...params, href: linkPath });
      }
      if (isExternalLink && target !== '_blank') {
        // console.log(linkPath);
        return (
          <a
            {...params}
            href={linkPath}
            target="_blank"
            rel="noopener noreferrer"
          />
        );
      }
      if (isHashLink || isExternalLink || isDownloadLink || isFileLink) {
        return <a {...params} href={linkPath} />;
      }
      return getLinkElement({ ...params, href: linkPath });
    },
    [getLinkElement, i18n.language],
  );

  return {
    getLink,
    getLinkElement,
  };
};

type RehypeReactProps = {
  children: Array<JSX.Element | string | null | undefined> | undefined;
  node?: Element | undefined;
  className?: string;
  title?: string;
};

export const useProcessor = () => {
  const { getLink } = useLink();

  const processor = useMemo(() => {
    return unified()
      .use(rehypeParse, { fragment: true })
      .use(rehypeReact, {
        passNode: true,
        Fragment: prod.Fragment,
        jsx: prod.jsx,
        jsxs: prod.jsxs,
        components: {
          div: (props: RehypeReactProps) => {
            if (props.className) {
              // Admonition
              if (props.className.includes('admonition ')) {
                if (props.node?.properties) {
                  const classNames = props.node.properties.className;
                  const title = props.title;
                  if (
                    classNames &&
                    Array.isArray(classNames) &&
                    classNames[1] &&
                    props.children &&
                    Array.isArray(props.children) &&
                    props.children[0]
                  ) {
                    return (
                      <Admonition
                        type={classNames[1] as AdmonitionType}
                        title={title}
                      >
                        {props.children}
                      </Admonition>
                    );
                  }
                }
              }
              // Authority Table
              if (
                props.className.includes('roles_and_permissions') &&
                !!props.node?.properties &&
                !!props.node?.properties.dataParameterName
              ) {
                return (
                  <ServiceAuthorityTable
                    serviceName={
                      props.node.properties.dataParameterName as string
                    }
                  />
                );
              }
            }
            return <div {...props} />;
          },
          a: (props) => getLink(props),
        },
      } as Options);
  }, [getLink]);

  return processor;
};
