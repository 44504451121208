import React, { useEffect, useState } from 'react';
import SideMenu from '@/components/common/SideMenu';
import LowerWrapper from '@/components/common/LowerWrapper';
import DocumentWrapper from '@/components/common/DocumentWrapper';
import Helmet from '@/components/common/Helmet';
import { Navigation } from '@/services/documents/navigation/types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ReleaseNotesData,
  useNavigation,
  useReleaseNotes,
} from '@/services/documents';
import { usePrevious } from 'react-use';
import ReleaseNoteList from '@/components/common/ReleaseNoteList';

const ReleaseNotesPage: React.FC = () => {
  const { i18n } = useTranslation();
  const { getNavigation } = useNavigation();
  const { getReleaseNotes } = useReleaseNotes();
  const [navigationData, setNavigationData] = useState<Navigation>();
  const prevLanguage = usePrevious(i18n.language);
  const params = useParams<'year'>();
  const [isLoading, setIsLoading] = useState(true);
  const [releaseNotesData, setReleaseNotesData] =
    useState<ReleaseNotesData | null>(null);

  // navigation.yml 取得
  useEffect(() => {
    (async () => {
      if (prevLanguage !== i18n.language) {
        const navigationRes = await getNavigation(
          i18n.language,
          'release-notes',
        );
        setNavigationData(navigationRes);
      }
    })();
  }, [getNavigation, i18n.language, prevLanguage]);

  // ページコンテンツ取得
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const path = params.year
        ? `release-notes/${params.year}/`
        : 'release-notes/';
      const releaseNotesRes = await getReleaseNotes(path);
      setIsLoading(false);
      setReleaseNotesData(releaseNotesRes);
    })();
  }, [getReleaseNotes, params.year]);

  return (
    <LowerWrapper>
      <Helmet title="Release Notes" />
      <SideMenu data={navigationData} isReleaseNoteListPage />
      <DocumentWrapper>
        {isLoading || !releaseNotesData ? null : (
          <ReleaseNoteList data={releaseNotesData} />
        )}
      </DocumentWrapper>
    </LowerWrapper>
  );
};

export default ReleaseNotesPage;
