import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { mq } from '@/breakpoints';
import { getBranchLink } from '@/util/branch';
import {
  isEnableAuth,
  isPilotAuto,
  isWebAuto,
  siteType,
} from '@/util/appConfig';
import { siteTitle } from '@/util/appConfig';
import { useHeader } from './useHeader';

const Header: React.FC = () => {
  const {
    user,
    handleClickLogin,
    handleClickLogout,
    handleClickNav,
    setAccountMenu,
    isTop,
    isScrolled,
    isCurrent,
    langPath,
    accountMenu,
  } = useHeader();

  useEffect(() => {
    if (!isEnableAuth) return;
    (async () => {
      try {
        if (user) {
          setAccountMenu(
            <li onClick={handleClickLogout}>
              <img src="/assets/common/header/logout.svg" />
            </li>,
          );
          return;
        }
        setAccountMenu(
          <li onClick={handleClickLogin}>
            <img src="/assets/common/header/login.svg" />
          </li>,
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, [handleClickLogin, handleClickLogout, user, setAccountMenu]);

  return (
    <Wrapper isTop={isTop} isScrolled={isScrolled} data-id="header">
      <LogoWrapper>
        <Link to={getBranchLink(langPath)}>
          <img src={`/assets/${siteType}/common/logo.svg`} alt={siteTitle} />
        </Link>
      </LogoWrapper>
      <CenterNavWrapper>
        <ul>
          {isWebAuto && (
            <>
              <li>
                <MenuLink
                  current={isCurrent('user-manuals')}
                  to={getBranchLink(`${langPath}user-manuals/`)}
                >
                  USER MANUALS
                </MenuLink>
              </li>
              <li>
                <MenuLink
                  current={isCurrent('developers-guides')}
                  to={getBranchLink(`${langPath}developers-guides/`)}
                >
                  DEVELOPER&apos;S GUIDES
                </MenuLink>
              </li>
            </>
          )}
          {isPilotAuto && (
            <li>
              <MenuLink
                current={isCurrent('reference-design')}
                to={getBranchLink(`${langPath}reference-design/`)}
              >
                REFERENCE DESIGN
              </MenuLink>
            </li>
          )}
          <li>
            <MenuLink
              current={isCurrent('release-notes')}
              to={getBranchLink(`${langPath}release-notes/`)}
            >
              RELEASE NOTES
            </MenuLink>
          </li>
          <li>
            <MenuLink
              current={isCurrent('faqs')}
              to={getBranchLink(`${langPath}faqs/`)}
            >
              FAQ &amp; CONTACTS
            </MenuLink>
          </li>
        </ul>
      </CenterNavWrapper>
      <RightNavWrapper>
        <ul>
          {accountMenu}
          <li onClick={handleClickNav}>
            <img src="/assets/common/header/menu.svg" />
          </li>
        </ul>
      </RightNavWrapper>
    </Wrapper>
  );
};

export default Header;

type WrapperProps = {
  isTop: boolean;
  isScrolled: boolean;
};

const Wrapper = styled.header<WrapperProps>`
  padding: 0 0 0 48px;
  height: 68px;
  background-color: white;
  box-shadow: 0px 2px 16px rgba(48, 152, 166, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: height 200ms;

  ${({ isTop }) => {
    if (isTop) {
      return css`
        box-shadow: none;
        position: fixed;
        width: 100%;

        body[data-type='pilotauto'] & {
          background-color: #f0f4ff;

          ${mq.md} {
            background-color: #fff;
          }
        }

        ${mq.md} {
          box-shadow: 0px 2px 16px rgba(48, 152, 166, 0.16);
        }
      `;
    }
  }}

  ${({ isTop, isScrolled }) => {
    if (isTop) {
      if (isScrolled) {
        return css`
          height: 68px;
        `;
      }

      return css`
        height: 108px;
      `;
    }
  }}

  ${mq.md} {
    height: 58px;
    padding: 0 0 0 20px;
    position: static;
  }

  img {
    width: 91px;
    height: 14px;
  }

  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
`;

const LogoWrapper = styled.h1`
  margin: 0;

  ${mq.md} {
    width: 78px;
    height: 100%;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    svg {
      width: 100%;
    }
  }

  img {
    display: block;
  }
`;

const CenterNavWrapper = styled.nav`
  display: flex;
  align-items: center;

  ${mq.md} {
    display: none;
  }

  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;

    li {
      font-size: 12px;
    }
  }
`;

const RightNavWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  ${mq.md} {
    height: 100%;
  }

  ul {
    text-align: flex-end;

    ${mq.md} {
      height: 100%;
    }

    li {
      cursor: pointer;
      padding: 0px 32px;
      box-sizing: border-box;
      border-left: solid 1px rgba(48, 152, 166, 0.16);

      img {
        width: 20px;
        height: 20px;
      }

      &:last-of-type {
        img {
          width: 18px;
          height: 14px;
        }
      }

      ${mq.md} {
        width: 90px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

type MenuLinkProps = {
  current: 0 | 1;
} & LinkProps;

const MenuLink = styled(Link)<MenuLinkProps>`
  color: ${({ theme }) => theme.textColor.primary};
  text-decoration: none;
  padding: 16px 20px;
  position: relative;
  transition: color 300ms;

  &:hover {
    color: #3098a6;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 2px;
    left: calc(50% - 2px);
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #3098a6;
    transition: transform 300ms;
    transform: scale(0);
  }

  ${({ current }) => {
    if (current) {
      return css`
        color: #3098a6;

        &::after {
          transform: scale(1);
        }
      `;
    }
  }}
`;
