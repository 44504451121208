import type { AuthorityTableData } from '@/services/config/authorityTable';
import { Fragment, memo } from 'react';
import type { AuthorityTableVisibleType } from '../types';
import { useLink } from '@/util/markdown';
import { useTranslation } from 'react-i18next';

type Props = {
  tableData: AuthorityTableData;
  visibleType: AuthorityTableVisibleType;
};

const AuthorityTableBody: React.FC<Props> = ({ tableData, visibleType }) => {
  const { t } = useTranslation();
  const { getLink } = useLink();

  return (
    <>
      {tableData.groups.map((group, i) => {
        const groupName = (
          <tr>
            <td className="action-name">{group.name}</td>
            {[...Array(tableData.roles.length + 1)].map((_, j) => (
              <td key={`action-td${j}`}>&nbsp;</td>
            ))}
          </tr>
        );

        const parameterName = group.parameters.map((parameter, j) => (
          <tr key={`parameter-tr${j}`}>
            <td className="parameter-name">
              {t('authority_table.parameter.apply_to')}
            </td>
            <td>&nbsp;</td>
            {parameter.result.map((result, k) => {
              if (result === 'None') {
                return (
                  <td className="parameter-none" key={`parameter-td${k}`} />
                );
              }
              const value = `${t(
                `authority_table.parameter.result.${result.toLowerCase()}`,
              )}${parameter.name}`;
              return (
                <td key={`parameter-td${k}`} className="parameter-value">
                  {value}
                </td>
              );
            })}
          </tr>
        ));

        const actions = group.actions.map((action, j) => {
          if (visibleType === 'summary') {
            return (
              <tr key={`action-tr${j}`}>
                <td>
                  {action.name}
                  {!!action.docs_path &&
                    action.docs_path.length > 0 &&
                    getLink({
                      href: action.docs_path,
                      children: <>?</>,
                    })}
                </td>
                <td>nbsp;</td>
                {tableData.roles.map((_, k) => (
                  <td key={`result${k}`}>
                    {action.result[k] ? <span /> : <>&nbsp;</>}
                  </td>
                ))}
              </tr>
            );
          }
          return (
            <>
              {action.scopes.map((scope, k) => (
                <tr key={`${scope.name}${k}`}>
                  <td>
                    {k === 0 ? (
                      <>
                        {action.name}
                        <a href={action.docs_path} rel="noreferrer noopener">
                          ?
                        </a>
                      </>
                    ) : (
                      <>&nbsp;</>
                    )}
                  </td>
                  <td className="scope-list">{scope.name}</td>
                  {tableData.roles.map((_, l) => (
                    <td key={`${scope.name}${k}${l}`}>
                      {scope.result[l] ? <span /> : <>&nbsp;</>}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          );
        });

        return (
          <Fragment key={`group${i}`}>
            {groupName}
            {parameterName}
            {actions}
          </Fragment>
        );
      })}
    </>
  );
};

export default memo(AuthorityTableBody);
